@tailwind base;

@tailwind components;

@tailwind utilities;

/* Hide loading indicator for YouGlish widget */
.ygProgress {
  display: none;
}
